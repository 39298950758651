.my-date-picker {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  border: none;
  box-shadow: 3px 0px 10px rgba(63, 81, 126, 0.11),
    0px 4px 8px rgba(63, 81, 126, 0.09);
  border-radius: 6px;
  z-index: 1;
}

.my-date-picker .react-datepicker__day--outside-month {
  opacity: 0;
  pointer-events: none;
}

.my-date-picker .react-datepicker__week {
  margin: 2px;
}

.my-date-picker .react-datepicker__day--range-start {
  position: relative;
  border-radius: 50%;
  background: #e2c32b !important;
  color: #fff !important;
}

.my-date-picker .react-datepicker__day--range-start::after {
  content: '';
  position: absolute;
  width: 50%;
  height: 100%;
  right: 0;
  top: 0;
  background: #f2f5fc;
  z-index: -1;
}

.my-date-picker .react-datepicker__day--range-end {
  position: relative;
  border-radius: 50%;
  background: #e2c32b !important;
  color: #fff !important;
}

.my-date-picker .react-datepicker__day--range-end::after {
  content: '';
  position: absolute;
  width: 50%;
  height: 100%;
  left: 0;
  top: 0;
  background: #f2f5fc;
  z-index: -1;
}

.my-date-picker-range {
  border-radius: 0;
  box-shadow: unset;
}

.my-date-picker-range
  .react-datepicker__day--in-range:not(
    .react-datepicker__day--range-start,
    .react-datepicker__day--range-end
  ) {
  background: #f2f5fc;
  color: #9dabc0;
  border-radius: 0;
}

.my-date-picker .react-datepicker__header {
  background: #fff;
  border: none;
}

.react-datepicker__day--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ) {
  background: #fff;
  color: #9dabc0;
  border-radius: 0;
}

.my-date-picker .react-datepicker__day-name {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #171c3d;
  width: 30px;
  margin: 0px;
  padding: 2px;
}

.my-date-picker .react-datepicker__triangle {
  display: none;
}
.my-date-picker .react-datepicker__month {
  margin: 0;
}
.my-date-picker .react-datepicker__month-container {
  margin: 0;
  padding: 20px;
  padding-top: 5px;
}

.my-day {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  color: #9dabc0;
  font-size: 12px;
  line-height: 15px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0px;
  padding: 2px;
}

.react-datepicker__day--keyboard-selected {
  background: #f2f5fc;
}

.react-datepicker__day--today {
  background: #f2f5fc;
  color: #171c3d;
  font-weight: 400;
}

.react-datepicker__day--selected {
  color: #fff;
  background: #e2c32b;
}

.my-day:hover {
  background: #fff;
  border: 1px solid #e2c32b;
  color: #9dabc0;
  border-radius: 100%;
}
